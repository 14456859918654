import React, { useState, useEffect } from 'react';
import {
  Modal, Box, Typography, IconButton, Button, TextField, Select, MenuItem, Switch, Stepper, Step, StepLabel, FormControlLabel, Checkbox
} from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getDtoFieldsForProduct, getDtoFieldsForOrder, getDtoFieldsForPO, createFTPIntegration } from '../../../api';
import CloseIcon from '@mui/icons-material/Close';

const OtherIntegrationWizardModal = ({ open, onClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [integrationType, setIntegrationType] = useState('FTP');
  const [operationType, setOperationType] = useState('Import');
  const [dataType, setDataType] = useState('Product');
  const [nickname, setNickname] = useState('');
  const [host, setHost] = useState('');
  const [port, setPort] = useState(21);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [directory, setDirectory] = useState('');
  const [isSftp, setIsSftp] = useState(false);
  const [schedule, setSchedule] = useState('30min');
  const [notes, setNotes] = useState('');
  const [customMappingEnabled, setCustomMappingEnabled] = useState(false);
  const [dtoFields, setDtoFields] = useState([]);
  const [fieldMappings, setFieldMappings] = useState({});

  const steps = ['Select Integration Type', 'Basic Information', 'FTP/SFTP Details', 'Scheduling', 'Custom File Mapping'];

useEffect(() => {
  const fetchDtoFields = async () => {
    try {
      let dtoResponse;
      if (dataType === 'Product') {
        dtoResponse = await getDtoFieldsForProduct();
      } else if (dataType === 'Order') {
        dtoResponse = await getDtoFieldsForOrder();
      } else if (dataType === 'PO') {
        dtoResponse = await getDtoFieldsForPO();
      }

      // Since the API response is directly an array, just check dtoResponse.length
      if (dtoResponse && dtoResponse.length > 0) {
        console.log("DTO Fields fetched:", dtoResponse);

        // Set the fetched fields
        setDtoFields(dtoResponse);

        // Initialize field mappings
        setFieldMappings(
          dtoResponse.reduce((acc, field) => {
            acc[field.name] = ''; // Empty string for mapping
            return acc;
          }, {})
        );
      }
    } catch (error) {
      console.error('Error fetching DTO fields:', error);
      toast.error('Failed to load DTO fields');
    }
  };

  if (customMappingEnabled) {
    fetchDtoFields(); // Fetch fields only when custom mapping is enabled
  }
}, [dataType, customMappingEnabled]);

  const handleNext = () => {
    setActiveStep(prevStep => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevStep => prevStep - 1);
  };

  const handleSave = async () => {
  if (password !== confirmPassword) {
    toast.error('Passwords do not match');
    return;
  }

  const integrationData = {
    integrationType,
    operationType,
    dataType,
    nickname,
    host,
    port,
    username,
    password,
    directory,
    isSftp,
    schedule,
    notes,
    customMappingEnabled,
    fieldMappings,
  };

  try {
    await createFTPIntegration(integrationData);
    toast.success('Integration saved successfully!');
    onClose();
  } catch (error) {
    console.error('Failed to save integration:', error);
    toast.error('Failed to save integration. Please try again.');
  }
};
  const handleMappingChange = (fieldName, value) => {
    setFieldMappings(prevMappings => ({
      ...prevMappings,
      [fieldName]: value,
    }));
  };

  return (
<Modal open={open} onClose={onClose} aria-labelledby="wizard-modal-title" aria-describedby="wizard-modal-description">
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxWidth: '600px',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '90vh', // Limits height for better scrolling
    }}
  >
    {/* 'x' close button in the top-right corner */}
    <IconButton
      sx={{ position: 'absolute', top: 8, right: 8 }}
      onClick={() => {
        console.log("Close button clicked");
        onClose();
      }}
    >
      <CloseIcon />
    </IconButton>

    <Typography id="wizard-modal-title" variant="h6" component="h2">
      Create FTP Integration
    </Typography>

    <Stepper activeStep={activeStep} sx={{ marginTop: '20px', marginBottom: '20px' }}>
      {steps.map((label, index) => (
        <Step key={index}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>

    {/* Step 1: Select Integration Type */}
    {activeStep === 0 && (
      <Box>
        <Typography variant="h6" gutterBottom>Select Integration Type</Typography>
        <Select fullWidth value={integrationType} onChange={e => setIntegrationType(e.target.value)}>
          <MenuItem value="FTP">FTP</MenuItem>
          {/* Future types can be added here */}
        </Select>
      </Box>
    )}

    {/* Step 2: Basic Information */}
    {activeStep === 1 && (
      <Box>
        <Typography variant="h6" gutterBottom>Basic Information</Typography>
        <Select fullWidth value={operationType} onChange={e => setOperationType(e.target.value)} sx={{ marginBottom: '16px' }}>
          <MenuItem value="Import">Import</MenuItem>
          <MenuItem value="Export">Export</MenuItem>
        </Select>

        <Select fullWidth value={dataType} onChange={e => setDataType(e.target.value)} sx={{ marginBottom: '16px' }}>
          <MenuItem value="Product">Product</MenuItem>
          <MenuItem value="Order">Order</MenuItem>
          <MenuItem value="PO">PO</MenuItem>
        </Select>

        <TextField label="Nickname" fullWidth value={nickname} onChange={e => setNickname(e.target.value)} sx={{ marginBottom: '16px' }} />
      </Box>
    )}

    {/* Step 3: FTP/SFTP Details */}
    {activeStep === 2 && (
      <Box>
        <Typography variant="h6" gutterBottom>FTP/SFTP Details</Typography>
        <FormControlLabel
          control={<Switch checked={isSftp} onChange={e => setIsSftp(e.target.checked)} />}
          label="Use SFTP"
          sx={{ marginBottom: '16px' }}
        />
        <TextField label="Host" fullWidth value={host} onChange={e => setHost(e.target.value)} sx={{ marginBottom: '16px' }} />
        <TextField label="Port" fullWidth type="number" value={port} onChange={e => setPort(e.target.value)} sx={{ marginBottom: '16px' }} />
        <TextField label="Username" fullWidth value={username} onChange={e => setUsername(e.target.value)} sx={{ marginBottom: '16px' }} />
        <TextField label="Password" fullWidth type="password" value={password} onChange={e => setPassword(e.target.value)} sx={{ marginBottom: '16px' }} />
        <TextField label="Confirm Password" fullWidth type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} sx={{ marginBottom: '16px' }} />
        <TextField label="Directory" fullWidth value={directory} onChange={e => setDirectory(e.target.value)} sx={{ marginBottom: '16px' }} />
      </Box>
    )}

    {/* Step 4: Scheduling */}
    {activeStep === 3 && (
      <Box>
        <Typography variant="h6" gutterBottom>Scheduling</Typography>
        <Select fullWidth value={schedule} onChange={e => setSchedule(e.target.value)} sx={{ marginBottom: '16px' }}>
          <MenuItem value="30min">Every 30 min</MenuItem>
          <MenuItem value="1hour">Every 1 hour</MenuItem>
          <MenuItem value="2hours">Every 2 hours</MenuItem>
          <MenuItem value="4hours">Every 4 hours</MenuItem>
          <MenuItem value="6hours">Every 6 hours</MenuItem>
          <MenuItem value="12hours">Every 12 hours</MenuItem>
          <MenuItem value="Daily">Daily</MenuItem>
        </Select>
        <TextField label="Notes" fullWidth multiline value={notes} onChange={e => setNotes(e.target.value)} sx={{ marginBottom: '16px' }} />
      </Box>
    )}

    {/* Step 5: Custom File Mapping */}
    {activeStep === 4 && (
      <Box sx={{ flexGrow: 1, overflowY: 'auto', paddingRight: '10px', marginBottom: '16px' }}>
        <Typography variant="h6" gutterBottom>Custom File Mapping</Typography>
        <FormControlLabel
          control={<Checkbox checked={customMappingEnabled} onChange={e => setCustomMappingEnabled(e.target.checked)} />}
          label="Enable Custom File Mapping"
          sx={{ marginBottom: '16px' }}
        />
        {customMappingEnabled && dtoFields.length > 0 ? (
          <Box>
            {dtoFields.map((field, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                  padding: '8px',
                  borderRadius: '4px',
                  backgroundColor: fieldMappings[field.name] !== undefined ? 'inherit' : '#f0f0f0',
                  opacity: fieldMappings[field.name] !== undefined ? 1 : 0.5,
                }}
              >
                <Checkbox
                  checked={fieldMappings[field.name] !== undefined}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    if (isChecked) {
                      setFieldMappings(prev => ({
                        ...prev,
                        [field.name]: fieldMappings[field.name] || '',
                      }));
                    } else {
                      setFieldMappings(prev => {
                        const newMappings = { ...prev };
                        delete newMappings[field.name];
                        return newMappings;
                      });
                    }
                  }}
                />
                <Typography sx={{ width: '30%', color: 'inherit' }}>{field.name}</Typography>
                <Typography sx={{ width: '20%', textAlign: 'center', color: 'inherit' }}>{field.type}</Typography>
                <TextField
                  fullWidth
                  sx={{ width: '50%' }}
                  value={fieldMappings[field.name] || ''}
                  onChange={e => handleMappingChange(field.name, e.target.value)}
                  placeholder="Mapped value"
                  disabled={fieldMappings[field.name] === undefined}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <Typography>No fields available for mapping.</Typography>
        )}
      </Box>
    )}

    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
      <Button disabled={activeStep === 0} onClick={handleBack}>
        Back
      </Button>
      {activeStep === steps.length - 1 ? (
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      ) : (
        <Button variant="contained" onClick={handleNext}>
          Next
        </Button>
      )}
    </Box>
  </Box>
</Modal>
  );
};

export default OtherIntegrationWizardModal;
