import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://stackrhub.com/api',
});

//const apiClient = axios.create({
//  baseURL: 'http://localhost:8080/api',
//});

//const apiClient = axios.create({
//  baseURL: process.env.NODE_ENV === 'production' ? 'https://stackrhub.com/api' : 'http://localhost:8080/api',
//});

export const sendContactRequest = async (formData) => {
  try {
    const response = await apiClient.post(`/email/external`, formData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// AUTH
export const login = async (email, password) => {
  try {
    const response = await apiClient.post(`/auth/login`, { email, password });
    localStorage.setItem('userId', response.data.id);
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('companyId', response.data.companyId);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const register = async (formData) => {
  try {
    const response = await apiClient.post(`/auth/register`, formData);

    localStorage.setItem('userId', response.data.id);
    localStorage.setItem('companyId', response.data.companyId);
    localStorage.setItem('token', response.data.token);

    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const isEmptyFilters = (filters) => {
  return Object.values(filters).every(value => value === '' || value === null);
};
// ORDERS

export const printOrderInvoice = async (id) => {
  try {
    const companyId = localStorage.getItem('companyId');
    const token = localStorage.getItem('token');
    const response = await apiClient.get(`/orders/${id}/invoice`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      params: {
        companyId: companyId,
      },
      responseType: 'blob',
    });

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url); // Opens the PDF in a new tab or print preview
  } catch (error) {
    console.error("Error fetching PDF:", error);
    throw error;
  }
};

export const deleteOrder = async (orderId) => {
  try {
    const token = localStorage.getItem('token');
    const response = await apiClient.delete(`/orders/${orderId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateOrder = async (id, orderData) => {
  try {
    const companyId = localStorage.getItem('companyId');
    const token = localStorage.getItem('token');
    const response = await apiClient.put(`/orders/${id}`, orderData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
        params: {
          companyId: companyId,
        },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating order:', error);
    throw error.response.data;
  }
};

export const getOrdersByCompanyId = async (page, size) => {
  try {
    const companyId = localStorage.getItem('companyId');
    const token = localStorage.getItem('token');

    const response = await apiClient.get(`/orders/company/${companyId}`, {
      params: { page, size },
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createOrder = async (orderData) => {
  const token = localStorage.getItem('token');
  const response = await apiClient.post(`/orders`, orderData, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};
export const getDtoFieldsForOrder = async () => {
  try {
    const token = localStorage.getItem('token');

    const response = await apiClient.get(`/orders/general/dto`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const fetchMappingFieldsForOrder = async () => {
  try {
    const token = localStorage.getItem('token');

    const response = await apiClient.get(`/orders/mapping-fields`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching mapping fields for order:', error);
    throw error;
  }
};
// Warehouses API Calls

export const getCompanyWarehouses = async () => {
  try {
    const companyId = localStorage.getItem('companyId');
    const token = localStorage.getItem('token');

    const response = await apiClient.get(`/warehouses/company/${companyId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching warehouses:", error);
    throw error;
  }
};

export const createWarehouse = async (warehouseData) => {
  try {
    const companyId = localStorage.getItem('companyId');
    const token = localStorage.getItem('token');

    const response = await apiClient.post(`/warehouses/company/${companyId}`, warehouseData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error creating warehouse:", error);
    throw error;
  }
};

export const updateWarehouseInfo = async (warehouseId, warehouseData) => {
  try {
    const token = localStorage.getItem('token');

    const response = await apiClient.put(`/warehouses/${warehouseId}`, warehouseData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error updating warehouse:", error);
    throw error;
  }
};

// MARKETS/INTEGRATIONS

export const fetchMarketIntegrations = async () => {
  const companyId = localStorage.getItem('companyId');
  const token = localStorage.getItem('token');
  const response = await apiClient.get(`/markets/comp/${companyId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
  });
  return response.data;
};

export const getIntegrationsByCompanyId = async () => {
  const companyId = localStorage.getItem('companyId');
  const token = localStorage.getItem('token');
  const response = await apiClient.get(`/integrations/comp/${companyId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
  });
  return response.data;
};

export const createFTPIntegration = async (integrationData) => {
  try {
  const companyId = localStorage.getItem('companyId');
  const token = localStorage.getItem('token');

    const response = await apiClient.post(`/ftp-integrations/comp/${companyId}`, integrationData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
export const saveMarketIntegration = async ({ productId, marketId }) => {
  const token = localStorage.getItem('token');
  const companyId = localStorage.getItem('companyId');
  const requestBody = {
    productId,
    marketId,
    companyId
  };

  try {
    const response = await apiClient.post(`/products/${productId}/markets`, requestBody, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error saving market integration:', error);
    throw new Error('Failed to save market integration');
  }
};


export const getEbayTokens = async (data) => {
  const token = localStorage.getItem('token');
  const companyId = localStorage.getItem('companyId');

  try {
    const response = await apiClient.get(`/api/ebay/callback`, {
      params: {
        ...data,
        companyId,
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching eBay tokens:', error.response || error);
    throw error.response?.data?.message || new Error('Failed to fetch eBay tokens. Please try again.');
  }
};

export const getEbayAuthUrl = async ({ nickname }) => {
  const token = localStorage.getItem('token');
  const companyId = localStorage.getItem('companyId');
  try {
    const response = await apiClient.post(`/ebay/v2/connect`, { nickname, companyId }, {
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching eBay auth URL:', error.response || error);
    throw error.response?.data?.message || new Error('Failed to fetch eBay auth URL.');
  }
};

export const getAmazonAuthUrl = async (data) => {
  const token = localStorage.getItem('token');
  try {
        const response = await apiClient.post(`/amazon/connect`, data, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || new Error('Error fetching Amazon auth URL');
  }
};

export const getEtsyAuthUrl = async (data) => {
  const token = localStorage.getItem('token');
  try {
      const response = await apiClient.post(`/etsy/connect`, data, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || new Error('Error fetching Etsy auth URL');
  }
};

export const getMagentoAuthUrl = async (data) => {
  const token = localStorage.getItem('token');
  try {
      const response = await apiClient.post(`/magento/connect`, data, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || new Error('Error fetching Magento auth URL');
  }
};

export const getPaypalClientId = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await apiClient.get(`/paypal-client-id`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
export const fetchTemplatesByCompanyId = async (companyId) => {
  try {
    const token = localStorage.getItem('token');
    const companyId = localStorage.getItem('companyId');
    const response = await apiClient.get(`/ebay/templates/company/${companyId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching templates:', error);
    throw error;
  }
};
export const uploadTemplateFile = async (file) => {
  try {
    const token = localStorage.getItem('token');
    const companyId = localStorage.getItem('companyId');
    const formData = new FormData();
    formData.append('file', file);

    const response = await apiClient.post(
      `/ebay/templates/comp/${companyId}/upload`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateTemplate = async (templateId, templateData) => {
  try {
    const token = localStorage.getItem('token');
    const response = await apiClient.put(`/ebay/templates/${templateId}`, templateData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating template:', error);
    throw error;
  }
};

export const createNewTemplate = async (templateData) => {
  try {
    const token = localStorage.getItem('token');
    const response = await apiClient.post('/ebay/templates', templateData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error creating template:', error);
    throw error;
  }
};

export const deleteTemplate = async (templateId) => {
  try {
    const token = localStorage.getItem('token');
    await apiClient.delete(`/ebay/templates/${templateId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error('Error deleting template:', error);
    throw error;
  }
};

export const fetchMappingFieldsForProduct = async () => {
  try {
    const token = localStorage.getItem('token');

    const response = await apiClient.get(`/products/mapping-fields`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching mapping fields for products:', error);
    throw error;
  }
};

// PRODUCTS
export const fetchProducts = async (filters, page, size, sortField, sortOrder) => {
  try {
    const token = localStorage.getItem('token');
    const companyId = localStorage.getItem('companyId');

    const requestData = {
      page,
      size,
      filters: filters || null,
      sortField: sortField || null,
      sortOrder: sortField ? sortOrder : null
    };

    const response = await apiClient.post(
      `/products/comp/${companyId}`,
      requestData,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};

export const fetchProductGroups = async () => {
  const token = localStorage.getItem('token');
  const companyId = localStorage.getItem('companyId');

  const response = await axios.get(`/comp/${companyId}/product-groups`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return response.data;
};

export const addProductToGroup = async (groupId, productId) => {
  const token = localStorage.getItem('token');
  const companyId = localStorage.getItem('companyId');

  await axios.post(`/comp/{companyId}/product-groups/${groupId}/add-product`, { productId }, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
};

export const updateProductImagesOrder = async (productId, imageOrder) => {
  try {
    const token = localStorage.getItem('token');
    const companyId = localStorage.getItem('companyId');

    const requestData = {
      imageOrder
    };

    const response = await apiClient.put(
      `/products/${companyId}/${productId}/images/order`,
      imageOrder,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createProduct = async (productData) => {
  try {
    const token = localStorage.getItem('token');
    const companyId = localStorage.getItem('companyId');

    const response = await apiClient.post(`/products/${companyId}`, productData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error from backend:', error);
    throw error.response.data;
  }
};

export const updateProduct = async (id, productData) => {
  try {
    const token = localStorage.getItem('token');
    const response = await apiClient.put(`/products/${id}`, productData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating product:', error);
    throw error.response.data;
  }
};
export const printProductBarcode = async (productId, barcodeType, identifier) => {
  const token = localStorage.getItem('token');
  try {
    const response = await apiClient.post(`/products/${productId}/barcode`, {
      barcodeType,
      identifier,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error printing barcode:", error);
    throw error;
  }
};

export const deleteProduct = async (productId) => {
  try {
    const token = localStorage.getItem('token');
    const response = await apiClient.delete(`/products/${productId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadProductImages = async (productId, images) => {
  try {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    images.forEach((image) => {
      formData.append('images', image.file);
    });
    await apiClient.post(`/products/${productId}/images`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    });
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteProductImage = async (imageId) => {
  try {
    const token = localStorage.getItem('token');
    const response = await apiClient.delete(`/images/${imageId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting product image:', error);
    throw error.response.data;
  }
};
export const getDtoFieldsForProduct = async () => {
  try {
    const token = localStorage.getItem('token');

    const response = await apiClient.get(`/products/general/dto`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const bulkImportProducts = async (file) => {
  try {
    const token = localStorage.getItem('token');
    const companyId = localStorage.getItem('companyId');
    const formData = new FormData();
    formData.append('file', file);

    const response = await apiClient.post(
      `/products/comp/${companyId}/bulk-import`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
// HELP

export const fetchHelpTopics = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await apiClient.get(`/help/topics`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchHelpTopicDetails = async (id) => {
  try {
    const response = await apiClient.get(`/help/topics/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// MY ACCOUNT
export const getUserAccountInfo = async (userId) => {
  try {
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    const response = await apiClient.get(`/users/${userId}/account`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};


// PURCHASE ORDERS

export const getPurchaseOrdersByCompanyId = async (page, size) => {
  try {
    const companyId = localStorage.getItem('companyId');
    const token = localStorage.getItem('token');
      const response = await apiClient.get(`/purchase-orders/company/${companyId}`, {
      params: { page, size },
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const createPurchaseOrder = async (formData) => {
  try {
    const token = localStorage.getItem('token');
    const response = await apiClient.post(`/purchase-orders`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDtoFieldsForPO = async () => {
  try {
    const token = localStorage.getItem('token');

    const response = await apiClient.get(`/purchase-orders/general/dto`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updatePurchaseOrder = async (id, formData) => {
  try {
        const token = localStorage.getItem('token');
        const response = await apiClient.put(`/purchase-orders/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPurchaseOrderById = async (id) => {
  try {
    const companyId = localStorage.getItem('companyId');
    const token = localStorage.getItem('token');
    const response = await apiClient.get(`/purchase-orders/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        companyId: companyId,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const printPurchaseOrderInvoice = async (id) => {
  try {
    const companyId = localStorage.getItem('companyId');
    const token = localStorage.getItem('token');
    const response = await apiClient.get(`/purchase-orders/${id}/invoice`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
      params: {
        companyId: companyId,
      },
      responseType: 'blob',
    });

    const blob = new Blob([response.data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url); // Opens the PDF in a new tab or print preview
  } catch (error) {
    console.error("Error fetching PDF:", error);
    throw error;
  }
};

export const deletePurchaseOrder = async (id) => {
  try {
          const userId = localStorage.getItem('userId');
          const token = localStorage.getItem('token');

    const response = await apiClient.delete(`/purchase-orders/${id}`, {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
        });

     return response.data;
  } catch (error) {
    throw error;
  }
};
export const fetchMappingFieldsForPO = async () => {
  try {
    const token = localStorage.getItem('token');

    const response = await apiClient.get(`/purchase-orders/mapping-fields`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching mapping fields for po:', error);
    throw error;
  }
};

// VENDORS

export const getVendorById = async (id) => {
  try {
    const companyId = localStorage.getItem('companyId');
    const token = localStorage.getItem('token');
    const response = await apiClient.get(`/vendor/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      params: {
        companyId: companyId,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getVendorsByCompanyId = async () => {
  try {
    const companyId = localStorage.getItem('companyId');
    const token = localStorage.getItem('token');
    const response = await apiClient.get(`/vendor/comp/${companyId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const searchVendorsTop3 = async (query) => {
  try {
    const companyId = localStorage.getItem('companyId');
    const token = localStorage.getItem('token');
    const response = await apiClient.get(`/vendor/search/${query}`, {
      params: { companyId: companyId },
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveVendor = async (vendor) => {
  try {
    const token = localStorage.getItem('token');
    const response = await apiClient.post(`/vendor`, vendor, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPurchaseOrdersByVendorId = async (vendorId) => {
  try {
    const token = localStorage.getItem('token');
    const response = await apiClient.get(`/vendor/${vendorId}/purchaseOrders`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteVendors = async (id) => {
  try {
          const userId = localStorage.getItem('userId');
          const token = localStorage.getItem('token');

    const response = await apiClient.delete(`/vendors/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
        });

     return response.data;
  } catch (error) {
    throw error;
  }
};

export const searchProductsTop3 = async (query) => {
  try {
    const token = localStorage.getItem('token');
    const companyId = localStorage.getItem('companyId');
    const response = await apiClient.get(`/products/search`, {
      params: { query, companyId },
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Did You Know card
export const getDidYouKnowCardData = async (cardId) => {
  try {
    const token = localStorage.getItem('token');
    const response = await apiClient.get(`/didYouKnow/${cardId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

//Queued Tasks
export const getQueuedTasks = async () => {
  try {
    const token = localStorage.getItem('token');
    const companyId = localStorage.getItem('companyId');
    const response = await apiClient.get(`/queued-tasks/${companyId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching queued tasks:', error);
    throw error;
  }
};
