import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem } from '@mui/material';
import { getEbayAuthUrl, getAmazonAuthUrl, getEtsyAuthUrl, getMagentoAuthUrl } from '../../../api'; // Adjust the import paths if necessary
import { toast } from 'react-toastify';

const AddMarketIntegrationModal = ({ open, onClose }) => {
  const [market, setMarket] = useState('');
  const [nickname, setNickname] = useState('');

  const handleSave = async () => {

    try {
      let response;
      if (market === 'ebay') {
        response = await getEbayAuthUrl({ nickname });
      } else if (market === 'amazon') {
        response = await getAmazonAuthUrl({ nickname });
      } else if (market === 'etsy') {
        response = await getEtsyAuthUrl({ nickname });
      } else if (market === 'magento') {
        response = await getMagentoAuthUrl({ nickname });
      }

      if (response?.authUrl) {
        window.location.href = response.authUrl;
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      toast.error(`Error getting ${market} auth URL`);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Add Market Integration</DialogTitle>
      <DialogContent>
        <TextField
          select
          fullWidth
          value={market}
          onChange={(e) => setMarket(e.target.value)}
          displayEmpty
        >
          <MenuItem value="" disabled>Select Market</MenuItem>
          <MenuItem value="ebay">eBay</MenuItem>
          <MenuItem value="amazon">Amazon</MenuItem>
          <MenuItem value="etsy">Etsy</MenuItem>
          <MenuItem value="magento">Magento</MenuItem>
        </TextField>
        <TextField
          fullWidth
          margin="normal"
          label="Nickname"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={handleSave} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMarketIntegrationModal;
